body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px white;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ddd;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #bbb;
}

.swiper {
  width: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.swiper-slide img {
  display: block;
  object-fit: cover;
}

.swiper-pagination-bullet {
  background: #fff !important;
  border-radius: 4px !important;
  width: 10px !important;
  height: 5px !important;
  opacity: 1 !important;
}

.swiper-pagination-bullet-active {
  background: #d72e59 !important;
  width: 15px !important;
  border-radius: 4px !important;
  height: 5px !important;
}

.link {
  stroke: #bbb !important;
  stroke-width: 1px;
}

.drag-handle {
  cursor: grab;
}

.chart-nav .MuiCollapse-wrapperInner li {
  border-left: 0px !important;
  background: none !important;
}

#role-name-input {
  border-color: red !important;
}

.MuiDataGrid-filterFormOperatorInput {
  display: none !important;
}

.css-aptufy-MuiInputBase-input-MuiInput-input {
  padding-left: 10px !important;
}

.css-17zrllr-MuiSelect-select-MuiInputBase-input-MuiInput-input {
  padding-left: 10px !important;
}

.quill {
  width: 100%;
  position: relative;
}

.ql-toolbar.ql-snow {
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
  position: absolute;
  bottom: 5px;
  z-index: 1000;
  border: 0 !important;
}

.ql-container.ql-snow {
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  border: 0 !important;
  border-bottom: 2px solid #ccc !important;
  height: calc(100% - 50px);
  /* background-color: #F4F6F8; */
}

.ql-toolbar.ql-snow .ql-formats {
  margin-right: 0 !important;
}

/* .MuiTreeItem-content {
  border-top-left-radius: 0px !important;
} */

.MuiDataGrid-main .MuiOutlinedInput-notchedOutline {
  border-width: 0px !important;
}

.highlight {
  background-color: #f88b9e33;
}

/* .MuiDataGrid-cell--pinnedLeft {
  align-items: center !important;
  justify-content: center !important;
  background-color: #d8dce6c2 !important;
  backdrop-filter: blur(10px);
}

.MuiDataGrid-cell--pinnedRight {
  align-items: center !important;
  justify-content: center !important;
  background-color: #d6dbe6c2 !important;
  backdrop-filter: blur(10px);
} */

.MuiDrawer-paper {
  background-color: #00000000 !important;
}

@media screen and (max-width: 900px) {
  .MuiDrawer-root,
  .MuiDrawer-paperAnchorRight {
    top: 64px !important;
  }

  .MuiDrawer-root .MuiDrawer-paperAnchorRight {
    width: 100%;
  }
}
